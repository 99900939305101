import { PrescriptionDto } from '../../api/backend-client/data-contracts';

export enum TriageType {
  LowBack = 'Low Back',
  Shoulder = 'Shoulder',
  Knee = 'Knee',
  Hip = 'Hip',
}

export const triageFormIdMap: Record<TriageType, string> = {
  [TriageType.LowBack]: 'kQlJxK0S',
  [TriageType.Shoulder]: 'Lf2QAiyP',
  [TriageType.Knee]: 'OC4xQwZJ',
  [TriageType.Hip]: 'sHnFFu2r',
};

export const LOW_BACK_TRIAGE_ASSESSMENT: PrescriptionDto = {
  id: 'LOW_BACK_TRIAGE_ASSESSMENT',
  title: 'Low Back Triage Assessment',
  configurable_exercise: [
    {
      reps: 2,
      sets: 1,
      duration: 0,
      exercise_id: 'LumbarFlexion-Triage',
      beginInInactiveState: true,
      checkInitialPose: true,
      initialPosePrompt: 'Face toward the side',
      movementPrompt:
        "Bend forward at your waist and reach your hands toward the ground. Move as far as you're able to, then return to stand.",
      completion: 'anyRep',
    },
    {
      reps: 2,
      sets: 1,
      duration: 0,
      exercise_id: 'StandingSpinalExtension-Triage',
      beginInInactiveState: true,
      checkInitialPose: true,
      initialPosePrompt: 'Face toward the side',
      movementPrompt:
        "Place your hands on your hips and lean your upper body backward as far as you're able to, then lean forward to return to stand.",
      completion: 'anyRep',
    },
    {
      reps: 2,
      sets: 1,
      duration: 0,
      exercise_id: 'SpinalRotation-Triage',
      beginInInactiveState: true,
      checkInitialPose: true,
      initialPosePrompt: 'Face forward with your feet together',
      movementPrompt:
        "Twist your upper body to one side, turning from your waist as far as you're able to. Then face forward and twist the opposite direction.",
      completion: 'anyRep',
    },
    {
      reps: 5,
      sets: 1,
      duration: 0,
      exercise_id: 'SitToStand-Triage',
      beginInInactiveState: true,
      checkInitialPose: true,
      initialPosePrompt: 'Sit and face toward the side',
      movementPrompt:
        'Move to a seated position with your arms crossed over your chest. Stand up and sit down five times in a row as fast as you can.',
      completion: 'anyRep',
    },
  ],
  create_clinician_id: '',
  create_clinician_email: '',
  last_modified_clinician_id: '',
  last_modified_clinician_email: '',
  is_active: false,
};

export const SHOULDER_TRIAGE_ASSESSMENT: PrescriptionDto = {
  id: 'SHOULDER_TRIAGE_ASSESSMENT',
  title: 'Shoulder Triage Assessment',
  configurable_exercise: [
    {
      reps: 2,
      sets: 1,
      duration: 0,
      exercise_id: 'ShoulderFlexion-Triage',
      beginInInactiveState: true,
      checkInitialPose: true,
      initialPosePrompt: 'Face toward the side',
      movementPrompt:
        "Raise one arm forward as far as you're able to, then lower your arm back down. Then repeat with the opposite arm.",
      completion: 'anyRep',
    },
    {
      reps: 2,
      sets: 1,
      duration: 0,
      exercise_id: 'ShoulderAbduction-Triage',
      beginInInactiveState: true,
      checkInitialPose: true,
      initialPosePrompt: 'Face forward with your feet together',
      movementPrompt:
        "Raise both arms out to the side, reaching as high as you're able to, then lower your arms back down.",
      completion: 'anyRep',
    },
    {
      reps: 5,
      sets: 1,
      duration: 0,
      exercise_id: 'SitToStand-Triage',
      beginInInactiveState: true,
      checkInitialPose: true,
      initialPosePrompt: 'Sit and face toward the side',
      movementPrompt:
        'Move to a seated position with your arms crossed over your chest. Stand up and sit down five times in a row as fast as you can.',
      completion: 'anyRep',
    },
  ],
  create_clinician_id: '',
  create_clinician_email: '',
  last_modified_clinician_id: '',
  last_modified_clinician_email: '',
  is_active: false,
};

export const KNEE_TRIAGE_ASSESSMENT: PrescriptionDto = {
  id: 'KNEE_TRIAGE_ASSESSMENT',
  title: 'Knee Triage Assessment',
  configurable_exercise: [
    {
      reps: 2,
      sets: 1,
      duration: 0,
      exercise_id: 'HeelSlide-Triage',
      beginInInactiveState: true,
      checkInitialPose: true,
      initialPosePrompt: 'Lie flat on your back and keep your legs extended',
      movementPrompt:
        'Slide one heel along the ground towards your hips, bending your knee and keeping the other leg straight. Return to the starting position and repeat with the other leg.',
      completion: 'anyRep',
    },
    {
      reps: 2,
      sets: 1,
      duration: 0,
      exercise_id: 'SeatedKneeExtension-Triage',
      beginInInactiveState: true,
      checkInitialPose: true,
      initialPosePrompt: 'Sit and face toward the side',
      movementPrompt:
        'Straighten one knee, lifting your foot off the ground, then lower it back down to the ground. Repeat with the opposite knee.',
      completion: 'anyRep',
    },
    {
      reps: 5,
      sets: 1,
      duration: 0,
      exercise_id: 'SitToStand-Triage',
      beginInInactiveState: true,
      checkInitialPose: true,
      initialPosePrompt: 'Sit and face toward the side',
      movementPrompt:
        'Move to a seated position with your arms crossed over your chest. Stand up and sit down five times in a row as fast as you can.',
      completion: 'anyRep',
    },
  ],
  create_clinician_id: '',
  create_clinician_email: '',
  last_modified_clinician_id: '',
  last_modified_clinician_email: '',
  is_active: false,
};

export const HIP_TRIAGE_ASSESSMENT: PrescriptionDto = {
  id: 'HIP_TRIAGE_ASSESSMENT',
  title: 'Hip Triage Assessment',
  configurable_exercise: [
    {
      reps: 2,
      sets: 1,
      duration: 0,
      exercise_id: 'SeatedHipInternalRotation-Triage',
      beginInInactiveState: true,
      checkInitialPose: true,
      initialPosePrompt: 'Sit and face toward the camera',
      movementPrompt:
        'Move one foot out to the side while keeping your knees close together. Then bring your foot back to the middle and repeat on the opposite side.',
      completion: 'anyRep',
    },
    {
      reps: 5,
      sets: 1,
      duration: 0,
      exercise_id: 'SitToStand-Triage',
      beginInInactiveState: true,
      checkInitialPose: true,
      initialPosePrompt: 'Sit and face toward the side',
      movementPrompt:
        'Move to a seated position with your arms crossed over your chest. Stand up and sit down five times in a row as fast as you can.',
      completion: 'anyRep',
    },
  ],
  create_clinician_id: '',
  create_clinician_email: '',
  last_modified_clinician_id: '',
  last_modified_clinician_email: '',
  is_active: false,
};
